import React, { useEffect, useState } from 'react';
import './swiper.css';
import css from './SectionWork.module.css';
import arrowRight from '../../../assets/icons/arrowRight.png';
import arrowLeft from '../../../assets/icons/arrowLeft.png';
import dynamic from 'next/dynamic';
import Lightbox from 'react-image-lightbox';
import classNames from 'classnames';

const Swiper =
  typeof window !== 'undefined'
    ? require('swiper/react').Swiper
    : dynamic(() => import('swiper/react'), {
        ssr: false,
      });
const SwiperSlide =
  typeof window !== 'undefined'
    ? require('swiper/react').SwiperSlide
    : dynamic(() => import('swiper/react'), {
        ssr: false,
      });
const Navigation =
  typeof window !== 'undefined'
    ? require('swiper').Navigation
    : dynamic(() => import('swiper'), {
        ssr: false,
      });

const SectionWork = props => {
  const {
    currentListing,
    featuredWorkPhotoUrl,
    portofolioPhotoUrls,
    originFeaturedWorkPhotoUrl,
    originPortofolioPhotoUrls,
    headshot,
  } = props;

  if (!currentListing?.id?.uuid) {
    return null;
  }

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const images = currentListing?.images || [];
  const imagesUrlsArray = [featuredWorkPhotoUrl, ...portofolioPhotoUrls];

  //TODO find a better way to populate the array
  const imagesUrls =
    imagesUrlsArray.length < 4
      ? [
          featuredWorkPhotoUrl,
          ...portofolioPhotoUrls,
          imagesUrlsArray[0],
          imagesUrlsArray[0],
          imagesUrlsArray[0],
        ]
      : [...imagesUrlsArray];

  // const originImagesUrlsArray = imagesUrls;
  const originImagesUrlsArray =
    originFeaturedWorkPhotoUrl && originPortofolioPhotoUrls
      ? [originFeaturedWorkPhotoUrl, ...originPortofolioPhotoUrls]
      : imagesUrlsArray;
  useEffect(() => {
    if (typeof window !== 'undefined') {
      require('swiper/css');
      require('swiper/css/bundle');
      require('swiper/css/navigation');
      require('swiper/css/pagination');
      require('react-image-lightbox/style.css');
    }
  }, []);

  return (
    <div className={css.sectionWork}>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={originImagesUrlsArray[photoIndex]}
          nextSrc={originImagesUrlsArray[(photoIndex + 1) % imagesUrls.length]}
          prevSrc={
            originImagesUrlsArray[
              (photoIndex + originImagesUrlsArray.length - 1) % originImagesUrlsArray.length
            ]
          }
          onCloseRequest={() => {
            setIsLightboxOpen(false);
          }}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + originImagesUrlsArray.length - 1) % originImagesUrlsArray.length
            )
          }
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % originImagesUrlsArray.length)}
        />
      )}
      <div
        className="custom_prev3"
        style={{ visibility: currentSlideIndex > 0 ? 'visible' : 'hidden' }}
      >
        <img className={css.arrow} src={arrowLeft} />
      </div>
      <Swiper
        modules={[Navigation]}
        navigation={{
          nextEl: '.custom_next3',
          prevEl: '.custom_prev3',
        }}
        slidesPerView={'auto'}
        spaceBetween={10}
        onSwiper={swiper => {}}
        onSlideChange={slide => {
          setCurrentSlideIndex(slide.activeIndex);
        }}
      >
        <div class="swiper-wrapper">
          <SwiperSlide className={classNames(css.headshotSwiperSlide, css.swiperSlide)}>
            <img
              src={headshot}
              className={css.slideImage}
              onClick={() => {
                setPhotoIndex(index);
                setIsLightboxOpen(true);
              }}
            />
          </SwiperSlide>

          {imagesUrls.map((url, index) => {
            return (
              <SwiperSlide className={css.swiperSlide} key={index}>
                <div className={css.slideImageWrapper}>
                  <img
                    src={url}
                    className={css.slideImage}
                    onClick={() => {
                      setPhotoIndex(index);
                      setIsLightboxOpen(true);
                    }}
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </div>
      </Swiper>
      <div className="custom_next3">
        <img className={css.arrow} src={arrowRight} />
      </div>
    </div>
  );
};

export default SectionWork;
