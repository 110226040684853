import React, { Component } from 'react';
import { array, arrayOf, bool, func, object, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  getValuesFromQueryString,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  countriesArray,
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  CancellationsAndFeesSection,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  sendEnquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
  onSendConciergeQuestion,
  requestToBook,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionAvatar from './SectionAvatar';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionMapMaybe from './SectionMapMaybe';
import css from './ListingPage.module.css';
import SectionServices from './SectionServices/SectionServices';
import quoteUp from '../../assets/icons/quoteUp.png';
import quoteDown from '../../assets/icons/quoteDown.png';
import arrowLeft from '../../assets/icons/arrowLeft.png';

import hoverPic from '../LandingPage/SectionFeaturedExperts/hoverPic.jpg';
import SectionWork from './SectionWork/SectionWork';
import ServiceDetails from '../ServiceDetailsPage/ServiceDetailsPage';
import { originPrefix } from '../../forms/EditListingPhotosForm/utils';
import CheckoutSection from './CheckoutSection/CheckoutSection';
import MessageForm from '../../forms/EnquiryForm/MessageForm';
const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      bookingModalOpen: false,
      focusedService: null,
      showServiceDetails: false,
      isMessage: false,
      isSpecificServiceInquiry: false,
    };
    this.onSubmitMessageForm = this.onSubmitMessageForm.bind(this);
  }

  onSubmitMessageForm(values) {
    const { history, params, onSendConciergeQuestion, currentUser, getListing } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const currentListing = ensureListing(getListing(listingId));
    const { message } = values;
    const focusedService = this.state.focusedService;
    const trimmedMessage = message?.trim();
    onSendConciergeQuestion(focusedService, currentListing, currentUser, trimmedMessage)
      .then(() => {
        this.setState({
          isMessageSuccess: true,
          enquiryModalOpen: true,
          isMessage: true,
          sendEnquiryError: false,
        });
      })
      .catch(err => {
        console.log('error case here');
        console.log(err);
        this.setState({
          isMessageSuccess: false,
          enquiryModalOpen: true,
          isMessage: true,
          sendEnquiryError: true,
        });
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onRequestToBook,
    } = this.props;

    const queryStringValues = getValuesFromQueryString();
    const disableButtons = queryStringValues?.id;
    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing));

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle2' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
    const category =
      publicData && publicData.category
        ? categoryLabel(categoryOptions, publicData.category)
        : null;
    const services = currentListing && currentListing.attributes.publicData?.services;
    const bookingLink = currentListing?.attributes?.publicData?.bookingLink;
    const servicesArray = services || [];
    const firstService = servicesArray.length > 0 && servicesArray[0];

    const listingAddress = currentListing?.attributes?.publicData?.location?.address;

    const featuredQuote = currentListing?.attributes?.publicData?.featuredQuote;
    const firstName = currentListing?.attributes?.publicData?.firstName;
    const lastInitial = currentListing?.attributes?.publicData?.lastInitial;
    const fullName = firstName + ' ' + lastInitial;
    const whyWeLoveDescription = currentListing?.attributes?.publicData?.whatWeLoveAbout;

    const secondImage =
      (currentListing?.images[1] &&
        currentListing?.images[1].attributes?.variants['default']?.url) ||
      hoverPic;

    const virtualServices = currentListing?.attributes?.publicData?.virtualServices === 'yes';

    const images = currentListing?.images || [];
    const headshotPhotoIds = publicData?.headshotPhotoIds || [];
    const headshotPhoto = images.find(i => headshotPhotoIds.includes(i?.id?.uuid));
    const headshotPhotoUrl = headshotPhoto && headshotPhoto.attributes.variants['default']?.url;

    const featuredWorkPhotoIds = publicData?.featuredWorkPhotoIds || [];
    const featuredWorkPhoto = images.find(i => featuredWorkPhotoIds.includes(i?.id?.uuid));
    const featuredWorkPhotoUrl =
      featuredWorkPhoto && featuredWorkPhoto.attributes.variants['default']?.url;

    const originFeaturedWorkPhotoIds =
      (publicData && publicData['featuredWorkPhotoIds' + originPrefix]) || [];
    const originFeaturedWorkPhoto = images.find(i =>
      originFeaturedWorkPhotoIds.includes(i?.id?.uuid)
    );
    const originFeaturedWorkPhotoUrl =
      originFeaturedWorkPhoto && originFeaturedWorkPhoto.attributes.variants['default']?.url;

    const portofolioPhotoIds = publicData?.portofolioPhotoIds || [];
    const portofolioPhotos = images.filter(i => portofolioPhotoIds.includes(i?.id?.uuid));
    const portofolioPhotoUrls =
      portofolioPhotos?.length > 0 &&
      portofolioPhotos.map(p => p?.attributes?.variants['default']?.url);

    const originPortofolioPhotoIds =
      (publicData && publicData['portofolioPhotoIds' + originPrefix]) || [];
    const originPortofolioPhotos = images.filter(i =>
      originPortofolioPhotoIds.includes(i?.id?.uuid)
    );
    const originPortofolioPhotoUrls =
      originPortofolioPhotos?.length > 0 &&
      originPortofolioPhotos.map(p => p?.attributes?.variants['default']?.url);

    const predefinedReviews = publicData?.reviews || [];
    const hasReviews = predefinedReviews.length > 0 && predefinedReviews.find(r => r);

    const serviceImage = this.state.focusedService?.image?.url || secondImage;

    const showCheckout = isAuthenticated && this.state.enquiryModalOpen && !this.state.isMessage;

    const titleSection = (
      <div className={css.titleSection}>
        <span className={css.titleTop}>{category}</span>
        <span className={css.titleBottom}>{title}</span>
      </div>
    );

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.mainWrapper}>
            <div className={css.listingPageWrapper}>
              <div className={css.listingTopSection}>
                <div className={css.sectionAuthorInfo}>
                  <div className={css.fullName}>{fullName}</div>
                  <div className={css.locationInfo}>
                    {title}
                    {' • '}
                    {listingAddress}
                    {virtualServices && ', VIRTUAL'}
                  </div>
                </div>
              </div>

              <div className={css.photosSection}>
                <img src={headshotPhotoUrl || hoverPic} className={css.headshotPhoto} />
                <div className={css.workSection}>
                  <SectionWork
                    currentListing={currentListing}
                    featuredWorkPhotoUrl={featuredWorkPhotoUrl}
                    originFeaturedWorkPhotoUrl={originFeaturedWorkPhotoUrl}
                    portofolioPhotoUrls={portofolioPhotoUrls}
                    originPortofolioPhotoUrls={originPortofolioPhotoUrls}
                    headshot={headshotPhotoUrl || hoverPic}
                  />
                </div>
              </div>
              <div className={css.textSection}>
                <div className={css.aboutSection}>
                  <h2 className={css.aboutTitle}>Why We Love Them</h2>
                  <p className={css.aboutText}>{whyWeLoveDescription}</p>
                </div>
                <div className={css.quoteSection}>
                  <img src={quoteUp} className={css.quote} />
                  <div className={css.featuredQuote}>{featuredQuote}</div>
                  <img src={quoteDown} className={css.quote} />
                </div>
              </div>

              <div className={css.sectionServices}>
                <p className={css.servicesTitle}>Services</p>
                <SectionServices
                  services={servicesArray}
                  setBookingModalOpen={value => {
                    this.setState({
                      bookingModalOpen: value,
                    });
                  }}
                  setFocusedService={value => this.setState({ focusedService: value })}
                  setEnquiryModalOpen={value => {
                    this.setState({ enquiryModalOpen: value });
                  }}
                  setShowServiceDetails={value => this.setState({ showServiceDetails: value })}
                  secondImage={hoverPic}
                  disableButtons={disableButtons}
                  setIsMessage={value => {
                    this.setState({
                      isMessage: value,
                    });
                  }}
                  fullName={fullName}
                  bookingLink={bookingLink}
                  onRequestToBook={() =>
                    onRequestToBook({ currentListing, focusedService: this.state.focusedService })
                  }
                  rawParams={rawParams}
                />
              </div>

              {hasReviews && (
                <div className={css.clientsReviews}>
                  <div className={css.titleReviews}>
                    <FormattedMessage id="SectionClientsReviews.titleLineOne2" />
                  </div>

                  <div className={css.steps}>
                    {predefinedReviews.map(r => {
                      return (
                        <div
                          className={css.step}
                          style={{ backgroundSize: 'cover' }}
                          // onMouseOver={e =>
                          //   (e.currentTarget.style.backgroundImage = `url(${coverPhotoUrl})`)
                          // }
                          // onMouseOut={e => (e.currentTarget.style.backgroundImage = `none`)}
                        >
                          <img src={quoteUp} className={css.quoteIcon} />

                          <h2 className={css.stepTitle}>{r?.review}</h2>
                          <p className={css.userTag}>- {r?.nameOfReviewer}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <CancellationsAndFeesSection></CancellationsAndFeesSection>
            </div>
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen && this.state.isMessage}
              onClose={() =>
                this.setState({
                  enquiryModalOpen: false,
                  isMessage: false,
                  isMessageSuccess: false,
                })
              }
              onManageDisableScrolling={onManageDisableScrolling}
              wide={this.state.isMessage}
            >
              <MessageForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={this.state.sendEnquiryError}
                onSubmit={this.onSubmitMessageForm}
                onClose={() =>
                  this.setState({
                    enquiryModalOpen: false,
                    isMessage: false,
                    isMessageSuccess: false,
                  })
                }
                inProgress={sendEnquiryInProgress}
                isMessage={this.state.isMessage}
                isMessageSuccess={this.state.isMessageSuccess}
                fullName={fullName}
                isSpecificServiceInquiry={this.state.isSpecificServiceInquiry}
              />
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: bool,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  // onSendEnquiry: (listingId, message, focusedService) =>
  onSendEnquiry: (listingId, focusedService, enquiryValues, messageMaybe) =>
    dispatch(sendEnquiry(listingId, focusedService, enquiryValues, messageMaybe)),
  onSendConciergeQuestion: (focusedService, currentListing, customerInfo, message) =>
    dispatch(onSendConciergeQuestion(focusedService, currentListing, customerInfo, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onRequestToBook: ({ currentListing, focusedService }) =>
    dispatch(requestToBook(currentListing, focusedService)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
