import React, { useState, useEffect } from 'react';
import css from './SectionServices.module.css';
import arrowRight from '../../../assets/icons/arrowRight.png';
import arrowLeft from '../../../assets/icons/arrowLeft.png';
// import { FormattedMessage } from '../../util/reactIntl';
// import { Button, NamedLink } from '../../components';
import dynamic from 'next/dynamic';
import './SectionCarousel.css';
import './swiper.css';
import classNames from 'classnames';
import { NamedLink } from '../../../components';

const Swiper =
  typeof window !== 'undefined'
    ? require('swiper/react').Swiper
    : dynamic(() => import('swiper/react'), {
        ssr: false,
      });
const SwiperSlide =
  typeof window !== 'undefined'
    ? require('swiper/react').SwiperSlide
    : dynamic(() => import('swiper/react'), {
        ssr: false,
      });
const Navigation =
  typeof window !== 'undefined'
    ? require('swiper').Navigation
    : dynamic(() => import('swiper'), {
        ssr: false,
      });
const Pagination =
  typeof window !== 'undefined'
    ? require('swiper').Pagination
    : dynamic(() => import('swiper'), {
        ssr: false,
      });

function SectionServices(props) {
  const {
    intl,
    services,
    setBookingModalOpen,
    setFocusedService,
    setEnquiryModalOpen,
    secondImage,
    setShowServiceDetails,
    disableButtons,
    fullName,
    setIsMessage,
    bookingLink,
    onRequestToBook,
    rawParams,
  } = props;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      require('swiper/css');
      require('swiper/css/bundle');
      require('swiper/css/navigation');
      require('swiper/css/pagination');
    }
  }, []);

  const screenWidth = typeof window !== 'undefined' && window.innerWidth;
  const slidesToShow = 1;

  if (typeof window === 'undefined') {
    return null;
  }
  return services && services.length > 0 ? (
    <div className={css.sectionContentListings}>
      <div
        className="custom_prev"
        style={{ visibility: currentSlideIndex > 0 ? 'visible' : 'hidden' }}
      >
        <img className={css.arrow} src={arrowLeft} />
      </div>
      <Swiper
        modules={[Navigation, Pagination]}
        navigation={{
          nextEl: '.custom_next',
          prevEl: '.custom_prev',
        }}
        spaceBetween={10}
        slidesPerView={slidesToShow}
        onSwiper={swiper => {}}
        onSlideChange={slide => {
          setCurrentSlideIndex(slide.activeIndex);
        }}
        grabCursor={true}
        pagination={{ clickable: true }}
      >
        {services.map((s, index) => {
          const typeOfServiceOptions = [
            {
              key: 'customProduct',
              label: 'Custom Product',
            },
            {
              key: 'experimental',
              label: 'Experiential',
            },
            {
              key: 'consultativeService',
              label: 'Consultative Service',
            },
          ];

          const descriptionText = s?.description;
          const isConsultativeService = s?.typeOfService === 'consultativeService';
          const isExperimentalService = s?.typeOfService === 'experimental';
          const isCustomGoodSerive = s?.typeOfService === 'customProduct';

          const serviceLabel = typeOfServiceOptions.find(x => x.key === s.typeOfService)?.label;

          const isVariablePrice = s?.pricingType === 'variable';

          const priceLabel = isVariablePrice ? s.variablePriceAmount : s.price;
          const includeStartingAt = s?.includeStartingAt === 'true';

          // for variable price we have units
          const variablePriceUnit = s?.variablePriceUnit && s?.pricingType === 'variable';
          const variablePriceUnitLabel = variablePriceUnit && ' per ' + s?.variablePriceUnit;

          const whatsIncludedLabel = Array.isArray(s?.whatsIncluded) && (
            <>
              {s.whatsIncluded.map((i, index) => (
                <p key={`whats-included-${index}`} className={css.listItem}>
                  • {i?.text}
                </p>
              ))}
            </>
          );
          //reminder
          const perfectForLabel = Array.isArray(s?.perfectFor) && (
            <>
              {s.perfectFor.map((i, index) => (
                <p key={`perfect-for-${index}`} className={css.listItem}>
                  • {i?.text}
                </p>
              ))}
            </>
          );

          const bookButtonLabel = 'REQUEST TO BOOK';

          const serviceImage = s?.image?.url || secondImage;
          return (
            <SwiperSlide key={s?.name}>
              <div className={css.serviceCard}>
                <div className={css.serviceCardLeft}>
                  <div className={css.serviceCardLeftContent}>
                    <div className={css.serviceCardLeftTop}>
                      <div className={css.serviceCardLeftTitle}>{s?.name}</div>
                      <p className={css.serviceCardLeftPrice}>
                        {`Price: ${includeStartingAt ? 'Starting at ' : ''} $${priceLabel} ${
                          variablePriceUnitLabel ? `/${variablePriceUnitLabel}` : ''
                        }`}
                      </p>
                    </div>
                    <div className={css.serviceCardLeftMiddle}>
                      <p className={css.descriptionText}>{descriptionText}</p>
                      <div className={css.serviceCardLeftSection}>
                        <p className={css.serviceCardLeftSectionLabel}>{'INCLUDES'}</p>
                        <div className={css.serviceCardLeftSectionInfo}>{whatsIncludedLabel}</div>
                      </div>
                      <div className={css.serviceCardLeftSection}>
                        <p className={css.serviceCardLeftSectionLabel}>{'PERFECT FOR'}</p>
                        <div className={css.serviceCardLeftSectionInfo}>{perfectForLabel}</div>
                      </div>
                    </div>
                  </div>
                  <div className={css.bookButtonsWrapper}>
                    <NamedLink
                      className={classNames(
                        disableButtons ? css.bookButtonLeftDisabled : css.bookButtonLeft,
                        css.bookButton
                      )}
                      name="ServiceDetailsPage"
                      params={{ id: rawParams.id, slug: rawParams.slug, name: s.name }}
                    >
                      FULL DETAILS
                    </NamedLink>
                    <a href={bookingLink}>
                      <div
                        className={classNames(
                          disableButtons ? css.bookButtonRightDisabled : css.bookButtonRight,
                          css.bookButton
                        )}
                        onClick={onRequestToBook}
                      >
                        {bookButtonLabel}
                      </div>
                    </a>
                  </div>
                </div>
                <div
                  className={css.serviceCardRight}
                  style={{
                    background: `url(${serviceImage})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                ></div>
              </div>
            </SwiperSlide>
          );
        })}

        <SwiperSlide>
          <div className={css.enquireCard}>
            <div className={css.enquireCardContent}>
              <p className={css.enquireCardTitle}>
                Don't see exactly what <br /> you're looking for?
              </p>

              <p className={css.enquireCardMessage}>
                Submit a custom request with our concierge for {fullName} to review
              </p>

              <div
                className={css.enquireCardButton}
                onClick={() => {
                  setIsMessage(true);
                  setEnquiryModalOpen(true);
                }}
              >
                Inquire
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="custom_next">
        <img className={css.arrow} src={arrowRight} />
      </div>
    </div>
  ) : null;
}

export default SectionServices;
